<template>
  <SaveSupplier
    @saveDetail="saveSupplier"
    @cancelCreate="cancelCreate"
    :supplierDetail="supplierDetail"
  />
</template>

<script>
import SaveSupplier from "../../../components/WebStore/suppliers/AddSupplier";
import { mapActions } from 'vuex';

export default {
  name: 'Add Supplier',
  components:{
    SaveSupplier
  },
  data: () => ({
    supplierDetail: {
      name: '',
      email: '',
      contactNumber: '',
      billingInfo: {
        firstName: '',
        lastName: '',
        address: {
          addressLineOne: '',
          addressLineTwo: '',
          suburb: '',
          country: '',
        }
      },
      paymentMethod: {
        accountName: '',
        accountId: '',
        cardId: '',
        frontImage: '',
        backImage:''
      },
      deliveryDetails: {
        deliveryFee: ''
      }
    }
  }),
  methods:{
    ...mapActions("supplier", ["addSupplier"]),
    saveSupplier(data){
      let self=this;
      this.addSupplier(data).then(res => {
        self.$vs.notify({
          title: "Supplier Add",
          text: "Supplier added successfully",
          color: "success",
        });
        self.$vs.loading.close();
        this.$router.push({ name: 'adminStoreSuppliers'})
      });
    },
    cancelCreate() {
      this.$router.push({ name: "adminStoreSuppliers"});
    },
  }
};
</script>
