<template>
  <vs-card>
    <vs-form autocomplete="off">
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input :success="!errors.first('basic.name') && supplierData.name != ''" val-icon-success="done"
              val-icon-danger="error" :danger="errors.first('basic.name') ? true : false"
              v-validate="'required|min:2|max:150|alpha_spaces'" name="name" data-vv-as="Name" label="Supplier Name"
              class="w-full" data-vv-scope="basic" :danger-text="errors.first('basic.name')"
              v-model="supplierData.name" />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input v-validate="'required'" name="email" data-vv-as="Email" label="Email Address" class="w-full"
              v-model="supplierData.email" />
            <span class="text-danger" style="font-size: 0.85em">{{
                errors.first("email")
            }}</span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input :success="
              !errors.first('basic.contactNumber') &&
              supplierData.contactNumber != ''
            " val-icon-success="done" val-icon-danger="error"
              :danger="errors.first('basic.contactNumber') ? true : false" v-validate="'required'" name="contactNumber"
              data-vv-as="Contact Number" label="Contact Number" class="w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.contactNumber')" v-model="supplierData.contactNumber" />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <h1>Delivery Details</h1>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input :success="
              !errors.first('basic.deliveryFee') &&
              supplierData.deliveryDetails.deliveryFee != ''
            " val-icon-success="done" val-icon-danger="error"
              :danger="errors.first('basic.deliveryFee') ? true : false" v-validate="'required|decimal:3'"
              name="deliveryFee" data-vv-as="Delivery Fee" label="Delivery Fee" class="w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.deliveryFee')" v-model="supplierData.deliveryDetails.deliveryFee" />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <h1>Settings</h1>
            <h6 class="mt-5">Public or private supplier</h6>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full mt-5 ml-5">
            <vs-radio v-model="privateStatus" vs-value="false"><strong>Public</strong></vs-radio>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-1/2 ml-12 mb-5">
            <p>
              Public suppliers allows all accounts to view products, but cannot
              purchase unless set in the account settings.
            </p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full mt-5 ml-5">
            <vs-radio v-model="privateStatus" vs-value="true"><strong>Private</strong></vs-radio>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-1/2 ml-12 mb-5">
            <p>
              Private suppliers and all their products are hidden from the
              store. Only accounts associated to this supplier can view and
              purchase their products.
            </p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="12" v-if="privateStatus == 'true'">
          <div class="w-1/2 mt-5 ml-12 mb-5">
            <label class="font-bold">Select Clinic</label>
            <v-select multiple :closeOnSelect="false" label="clinicName" :options="clinicList" value="value"
              :reduce="(option) => option._id" v-model="supplierData.privateAssignedClinics"
              data-vv-as="AssignedClinics" data-vv-scope="basic" name="privateAssignedClinics"
              class="w-full vs-con-input">
            </v-select><br />
            <span class="text-danger" style="font-size: 0.85em">{{
                errors.first("privateAssignedClinics")
            }}</span>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <h1>Billing Information</h1>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input :success="
              !errors.first('basic.firstName') &&
              supplierData.billingInfo.firstName != ''
            " val-icon-success="done" val-icon-danger="error"
              :danger="errors.first('basic.firstName') ? true : false"
              v-validate="'required|min:2|max:150|alpha_spaces'" name="firstName" data-vv-as="First Name"
              label="First Name" class="w-full" data-vv-scope="basic" :danger-text="errors.first('basic.firstName')"
              v-model="supplierData.billingInfo.firstName" />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input :success="
              !errors.first('basic.lastName') &&
              supplierData.billingInfo.lastName != ''
            " val-icon-success="done" val-icon-danger="error" :danger="errors.first('basic.lastName') ? true : false"
              v-validate="'required|min:2|max:150|alpha_spaces'" name="lastName" data-vv-as="Last Name"
              label="Last Name" class="w-full" data-vv-scope="basic" :danger-text="errors.first('basic.lastName')"
              v-model="supplierData.billingInfo.lastName" />
          </div>
        </vs-col>
        <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <div
              class="vs-component vs-con-input-label vs-input w-full vs-input-primary"
              :class="{
                  'input-icon-validate-danger':
                    errors.first('addressLineOne') ||
                    (supplierData.billingInfo.address.addressLineOne &&
                      !supplierData.billingInfo.address.geometry.coordinates[0] &&
                      !supplierData.billingInfo.address.geometry.coordinates[1]) ||
                    checkDisplayAddress != supplierData.billingInfo.address.addressLineOne,
                  'input-icon-validate-success':
                    supplierData.billingInfo.address.geometry.coordinates[0] &&
                      supplierData.billingInfo.address.geometry.coordinates[1] &&
                    checkDisplayAddress == supplierData.billingInfo.address.addressLineOne
                }"
            >
              <label for class="vs-input--label">Address 1</label>
              <div class="vs-con-input">
                <input
                  type="text"
                  placeholder
                  ref="autocomplete"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  data-vv-as="addressLineOne"
                  name="addressLineOne"
                  v-model="supplierData.billingInfo.address.addressLineOne"
                  class="vs-inputx vs-input--input normal"
                  :class="{ hasValue: supplierData.billingInfo.address.addressLineOne }"
                  :danger-text="'asd'"
                  style="border: 1px solid rgba(0, 0, 0, 0.2);"
                />
                <span class="input-icon-validate vs-input--icon-validate">
                    <i
                      v-if="
                        supplierData.billingInfo.address.geometry.coordinates[0] &&
                      supplierData.billingInfo.address.geometry.coordinates[1] &&
                          checkDisplayAddress ==
                            supplierData.billingInfo.address.addressLineOne
                      "
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                    >done</i
                    >
                    <i
                      v-else-if="
                        errors.first('addressLineOne') ||
                          (supplierData.billingInfo.address.addressLineOne &&
                            !supplierData.billingInfo.address.geometry.coordinates[0] &&
                      !supplierData.billingInfo.address.geometry.coordinates[1]) ||
                          checkDisplayAddress !=
                            supplierData.billingInfo.address.addressLineOne
                      "
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                    >error</i
                    >
                  </span>
              </div>
            </div>
            <span
              class="text-danger text-sm"
              style="font-size: .65rem !important;padding: 2px 4px;padding-bottom: 4px;display: block;"
              v-if="
                  errors.first('addressLineOne') ||
                    (supplierData.billingInfo.address.addressLineOne &&
                      !supplierData.billingInfo.address.geometry.coordinates[0] &&
                      !supplierData.billingInfo.address.geometry.coordinates[1]) ||
                    checkDisplayAddress != supplierData.billingInfo.address.addressLineOne
                "
            >Enter valid address.</span
            >
                       <vs-input
                          :success="!errors.first('basic.addressLineOne') && supplierData.billingInfo.address.addressLineOne!=''"
                          val-icon-success="done"
                          val-icon-danger="error"
                          ref="autocomplete"
                          :danger="(errors.first('basic.addressLineOne') ? true : false)"
                          v-validate="'required | min:5'"
                          name="addressLineOne"
                          data-vv-as="Address 1"
                          label="Address 1"
                          class="w-full"
                          data-vv-scope="basic"
                          :danger-text="errors.first('basic.addressLineOne')"
                          v-model="supplierData.billingInfo.address.addressLineOne"
                        />
          </div>
        </vs-col> -->
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <vs-input :success="
              !errors.first('basic.addressLineOne') &&
              supplierData.billingInfo.address.addressLineOne != ''
            " val-icon-success="done" val-icon-danger="error"
              :danger="errors.first('basic.addressLineOne') ? true : false" v-validate="'min:5'" name="addressLineOne"
              data-vv-as="Address 1" label="Address 1" class="w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.addressLineOne')"
              v-model="supplierData.billingInfo.address.addressLineOne" />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <vs-input :success="
              !errors.first('basic.addressLineTwo') &&
              supplierData.billingInfo.address.addressLineTwo != ''
            " val-icon-success="done" val-icon-danger="error"
              :danger="errors.first('basic.addressLineTwo') ? true : false" v-validate="'min:5'" name="addressLineTwo"
              data-vv-as="Address 1" label="Address 2" class="w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.addressLineTwo')"
              v-model="supplierData.billingInfo.address.addressLineTwo" />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <vs-input :success="
              !errors.first('basic.country') &&
              supplierData.billingInfo.address.country != ''
            " val-icon-success="done" val-icon-danger="error" :danger="errors.first('basic.country') ? true : false"
              v-validate="'alpha_spaces|min:2'" name="country" data-vv-as="Country" label="Country" class="w-full"
              data-vv-scope="basic" :danger-text="errors.first('basic.country')"
              v-model="supplierData.billingInfo.address.country" />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <vs-input :success="
              !errors.first('basic.suburb') &&
              supplierData.billingInfo.address.suburb != ''
            " val-icon-success="done" val-icon-danger="error" :danger="errors.first('basic.suburb') ? true : false"
              v-validate="'alpha_spaces|min:2'" name="suburb" data-vv-as="City" label="City" class="w-full"
              data-vv-scope="basic" :danger-text="errors.first('basic.suburb')"
              v-model="supplierData.billingInfo.address.suburb" />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <h1>Payment Details</h1>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input :success="
              !errors.first('basic.accountName') &&
              supplierData.paymentMethod.accountName != ''
            " val-icon-success="done" val-icon-danger="error"
              :danger="errors.first('basic.accountName') ? true : false" v-validate="'alpha_spaces|min:2|max:150'"
              name="accountName" data-vv-as="Account Name" label="Account Name" class="w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.accountName')" v-model="supplierData.paymentMethod.accountName" />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input :success="
              !errors.first('basic.accountId') &&
              supplierData.paymentMethod.accountId != ''
            " val-icon-success="done" val-icon-danger="error"
              :danger="errors.first('basic.accountId') ? true : false" v-validate="" name="accountId"
              data-vv-as="Account Id" label="Account Id" class="w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.accountId')" v-model="supplierData.paymentMethod.accountId" />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-6">
            <vs-input :success="
              !errors.first('basic.cardId') &&
              supplierData.paymentMethod.cardId != ''
            " val-icon-success="done" val-icon-danger="error" :danger="errors.first('basic.cardId') ? true : false"
              v-validate="" name="cardId" data-vv-as="BSB" label="BSB" class="w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.cardId')" v-model="supplierData.paymentMethod.cardId" />
          </div>
        </vs-col>
      </vs-row>


      <vs-row>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full m-5" align="right">
            <vs-button :disabled="!validateForm" @click="saveData" icon-pack="feather" class="mr-2">Create Supplier
            </vs-button>
            <vs-button color="danger" @click="cancelHandler" icon-pack="feather">Cancel</vs-button>
          </div>
        </vs-col>
      </vs-row>
    </vs-form>
  </vs-card>
</template>

<script>
import vSelect from "vue-select";
import axios from "@/axios";
import { mapActions } from "vuex";
import SupplierTrackingList from "./SupplierTrackingList";

export default {
  name: "AddSupplier",
  components: {
    vSelect,
    SupplierTrackingList,
  },
  props: {
    supplierDetail: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      supplierData: this.supplierDetail,
      trackingOptions: [],
      privateStatus: "",
      clinicList: [],
      privateAssignedClinics: true,
      checkDisplayAddress: "",
      addressData: [],
      coordinates: [],
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.supplierData.email.length !== 0 &&
        this.supplierData.name !== "" &&
        this.supplierData.contactNumber !== "" &&
        this.supplierData.deliveryDetails.deliveryFee !== "" &&
        this.supplierData.billingInfo.addressLineOne !== ""
      );
      /* &&
       this.supplierData.paymentMethod.frontImage !== '' &&
       this.supplierData.paymentMethod.backImage !== ''*/
    },
  },
  methods: {
    ...mapActions("clinic", ["fetchAllClinic"]),

    getAllClinic() {
      this.fetchAllClinic().then(async (res) => {
        this.$vs.loading.close();
        this.clinicList = res.data.data;
      });
    },
    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key,
        scale: 0.6,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png,image/jpeg",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "frontImage") {
            self.supplierData.paymentMethod.frontImage = res.data.Location;
          }
          if (key === "backImage") {
            self.supplierData.paymentMethod.backImage = res.data.Location;
          }
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    async saveData() {
      let isValid = await this.$validator.validateAll("basic");

      let regex =
        /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
      let emailArr = [];
      if (this.supplierData.email.length > 1) {
        emailArr = this.supplierData.email.split(",");
        emailArr.forEach((item) => {
          if (item === "" || !regex.test(item.replace(/\s/g, ""))) {
            isValid = false;
          }
          if (isValid === false) {
            this.errors.add({
              field: "email",
              msg: "The Email field format is invalid",
            });
          }
        });
      }
      if (
        this.supplierData.privateStatus === true &&
        this.supplierData.privateAssignedClinics.length === 0
      ) {
        this.privateAssignedClinics = false;
        this.errors.add({
          field: "privateAssignedClinics",
          msg: "Clinic/s must be assigned if supplier is private",
        });
      } else {
        this.errors.remove("privateAssignedClinics");
      }

      if (!isValid || !this.privateAssignedClinics) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      this.supplierData.billingInfo.address.geometry.coordinates =
        this.coordinates;
      this.supplierData.email = emailArr;
      this.supplierData.deliveryDetails.deliveryFee =
        this.supplierData.deliveryDetails.deliveryFee * 100;
      this.supplierData.trackingOptions = this.trackingOptions;
      this.$emit("saveDetail", this.supplierData);
    },
    cancelHandler() {
      this.$emit("cancelCreate");
    },
    changed(data) {
      this.trackingOptions = data;
    },
  },
  mounted() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      { types: ["geocode"], componentRestrictions: { country: "au" } }
    );
    this.autocomplete.addListener("place_changed", () => {
      let place = this.autocomplete.getPlace();
      this.errors.remove("addressLineOne");
      let ac = place.address_components;
      this.addressData = place.address_components;
      this.checkDisplayAddress = place.formatted_address;
      let lat = place.geometry.location.lat();
      let lon = place.geometry.location.lng();
      this.coordinates[0] = lon;
      this.coordinates[1] = lat;
      this.supplierData.billingInfo.address.addressLineOne =
        place.formatted_address;
      this.supplierData.billingInfo.address.geometry.coordinates[0] = lon;
      this.supplierData.billingInfo.address.address.geometry.coordinates[1] =
        lat;
    });
  },
  watch: {
    privateStatus: function (val) {
      if (val == "true") {
        this.supplierData.privateStatus = true;
      } else {
        this.supplierData.privateStatus = false;
        this.supplierData.privateAssignedClinics = [];
      }
    },
    "supplierData.privateAssignedClinics": function (val) {
      if (
        this.supplierData.privateStatus === true &&
        this.supplierData.privateAssignedClinics.length > 0
      ) {
        this.privateAssignedClinics = true;
        this.errors.remove("privateAssignedClinics");
      }
    },
  },
  async created() {
    await this.getAllClinic();
  },
};
</script>

<style scoped>
</style>
